import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

function FlexBox({
  as: Element,
  innerContainerAs: InnerElement,
  spacing,
  justify,
  align,
  direction,
  children,
  className,
  ...otherProps
}) {
  const innerContainerStyleClasses = classnames(
    'l-flex-box__inner-container',
    { [`l-flex-box__inner-container--spacing:${spacing}`]: spacing },
    { [`l-flex-box__inner-container--justify:${justify}`]: justify },
    { [`l-flex-box__inner-container--align:${align}`]: align },
    { [`l-flex-box__inner-container--direction:${direction}`]: direction },
  );
  
  return (
    <Element 
      className={classnames('l-flex-box', className)}
      {...otherProps}
    >
      <InnerElement
        className={innerContainerStyleClasses}
      >
        {children}
      </InnerElement>
    </Element>
  );
}

FlexBox.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  innerContainerAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  children: PropTypes.node,
  className: PropTypes.string,
  spacing: PropTypes.oneOf(['x-small', 'small', 'base', 'medium', 'large', 'x-large']),
  justify: PropTypes.oneOf(['normal', 'flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
  align: PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end']),
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
};

FlexBox.defaultProps = {
  as: 'div',
  innerContainerAs: 'div',
  spacing: undefined,
  justify: 'flex-start',
  align: 'flex-start',
  children: undefined,
  className: undefined,
  direction: 'row',
};

export default FlexBox;